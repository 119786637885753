import { createApp } from "vue";
import "es6-promise/auto";
import "./assets/styles/app.scss";
import VueSelect from "vue-select";
import DOMPurify from 'dompurify';

import router from "./router";
import MainLayout from "./components/layouts/MainLayout";
import store from "./store";

import { getJSONFromUrlParams } from "./utils/URLParser";

// Import global componenents
import QuestionLayout from "./components/QuestionLayout";
import TooltipElement from "./components/fields/TooltipElement";

/* eslint-disable no-new */
const app = createApp({
  template: "<main-layout/>",
  components: {
    MainLayout,
  },
  created() {
    const codeClient = getJSONFromUrlParams().codeClient;
    if (codeClient) {
      store.dispatch("fetchSurvey", codeClient);
    }
  },
  watch: {
    "$store.state.isSurveyCompleted": function (isSurveyCompleted) {
      if (isSurveyCompleted === true) {
        router.push({ name: "Complet" });
      }
    },
  },
});

app.use(router);
app.use(store);

app.mount("#root");

app.component("question-layout", QuestionLayout);
app.component("tooltip-element", TooltipElement);
app.component("v-select", VueSelect);

app.directive('sane-html', (el, binding) => {
  el.innerHTML = DOMPurify.sanitize(binding.value);
});

if (__DEV__) {
  // Remove productionTip
  app.config.productionTip = false;
}

export default app;
