<template>
  <div :class="`${question.answerColClass || 'col'} field`">
    <a class="btn btn-primary" @click="addFilleul" target="_blank">{{ question.buttonLabel }}</a>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";

export default {
  props: ["question", "path", "responsePath"],
  computed: {
    ...mapState({
      nbFilleuls: state => {
        return state.sections.globals.nombreFilleuls;
      },
    }),
  },
  methods: {
    ...mapActions({
      addFilleul: "setFilleulState",
    }),
  },
};
</script>
