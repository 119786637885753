<template>
  <footer class="confirmation-footer">
    <div class="container">
      <div class="mentions-legales" v-if="showMentionLegales">
        Les données personnelles collectées par Groupama Loire Bretagne, responsable de traitement,
        sont nécessaires pour gérer votre participation à l’opération de parrainage.<br />
        Conformément à la règlementation relative à la protection des données personnelles notamment
        le RGPD (UE 2016/679) et la loi Informatique et Libertés n° 78-17 du 6 Janvier 1978
        modifiée, vous pouvez exercer vos droits d’accès, de rectification, de suppression, de
        limitation, de portabilité et d'opposition en vous adressant au Délégué à la Protection des
        Données de Groupama Loire Bretagne, par courrier à l’adresse : 23, Boulevard Solférino
        CS51209 35012 RENNES cedex ou par mail à l’adresse
        <a href="mailto:contactdrpo@groupama-loire-bretagne.fr"
          >contactdrpo@groupama-loire-bretagne.fr</a
        >. Vous en avez informé votre filleul. Pour une information détaillée concernant vos données
        personnelles, vous pouvez consulter notre Politique de protection des données, retrouver les
        informations relatives aux traitements mis en œuvre et aux modalités d’exercice de vos
        droits sur notre site internet
        <a target="_blank" href="https://www.groupama.fr">https://www.groupama.fr</a>
        , rubrique « Données personnelles », ou vous adresser au Délégué à la Protection des données
        à l’adresse indiquée ci-avant.<br />
        En remplissant ce questionnaire portant sur vos projets et vos changements de vie, vous
        autorisez Groupama, à vous contacter par email ou par téléphone, pour vous proposer de
        l'information, des conseils, des services et des offres adaptés à vos besoins, en lien avec
        la mise en œuvre de votre projet. Les réponses aux questions sont facultatives et les
        informations recueillies ne seront en aucun cas transmises à des sociétés extérieures.
      </div>
      <div class="text-center" v-if="showMentionLegales">
        <a href="/mentions-legales.pdf" target="_blank"> Mentions légales</a>
      </div>
    </div>
  </footer>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters({
      showMentionLegales: "isDisabled",
    }),
  },
};
</script>
<style lang="scss" scoped></style>
