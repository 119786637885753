<template>
  <div id="layout">
    <v-header />
    <section class="wrapper">
      <app-header v-if="showAppHeader" />
      <section class="content">
        <router-view v-slot="{ Component }">
          <Transition name="fade" mode="out-in">
            <keep-alive v-if="$route.meta && $route.meta.keepAlive !== false">
              <component :is="Component" />
            </keep-alive>
            <component :is="Component" v-else />
          </Transition>
        </router-view>
        <app-footer />
      </section>
    </section>
  </div>
</template>
<script>
import { mapGetters, mapState } from "vuex";

import VHeader from "./Header";
import AppHeader from "./AppHeader";
import AppFooter from "./AppFooter";

export default {
  components: {
    VHeader,
    AppHeader,
    AppFooter,
  },

  computed: {
    ...mapGetters({
      showAppHeader: "isDisabled",
    }),
    ...mapState({
      // showAppHeader: state => state.showAppHeader,
      showAppFooter: state => state.showAppFooter,
      // section(state) {
      //   return state.survey.sections[this.$route.params.section];
      // },
    }),
  },
};
</script>
