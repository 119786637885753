import axios from "axios";
import { get, set, dropRight, concat, forEach, omitBy } from "lodash";
import { set as setFP } from "lodash/fp";
import moment from "moment";
import { uuid } from "vue-uuid";

import {
  SET_SURVEY,
  CHECK_ERROR_FP,
  SET_VALUE_GLOBAL,
  TOGGLE_BT_NEXT,
  SET_VALIDATE_SECTION,
  GET_POSTAL_CODE,
  CHECK_ERROR,
  SET_VALUE,
  SET_VALUE_DATE,
  RESET_QUESTION,
  VALIDATE_SECTION,
  FETCH_SURVEY,
  SET_ID,
  RESET_VALUE,
  ADD_PREVIOUS_SECTION,
  POP_PREVIOUS_SECTION,
  CHECK_COMPLETED,
  SET_FILLEUL,
  RESET_FILLEULS,
  REMOVE_FILLEUL
} from "./actions";

import { getError } from "../utils/validate";
import {
  getPath,
  getProp,
  execQuestionDeep,
  execSectionDeep,
  execSurveyDeep,
  isRulesVisible
} from "../utils/SurveyUtils";
import { getResponseFromServer } from "../utils/ServerParser";

// tous les changements du store doivent être fait ici
const mutations = {
  [CHECK_ERROR]: (state, { path, responsePath, value }) => {
    const question = get(state.survey, path, {});
    // Use set (not setFP) to not re-create the question with the new error props (don't display the new error on user input)
    set(state.survey, getPath(path, responsePath, "error"), getError(value, question.rules));
  },
  [CHECK_ERROR_FP]: (state, { path, responsePath, value }) => {
    const question = get(state.survey, path, {});
    let survey = state.survey;
    survey = setFP(
      getPath(path, responsePath, "error"),
      getError(value, question.rules),
      state.survey
    );
    if (getError(value, question.rules)) {
      survey = setFP("sections.risques.isValidated", false, survey);
    }
    state.survey = survey;
  },
  [ADD_PREVIOUS_SECTION]: (state, value) => {
    let survey = state.survey;
    let previousSections = get(survey, "sections.globals.previousSection");
    survey = setFP(
      "sections.globals.previousSection",
      previousSections !== null && Array.isArray(previousSections)
        ? concat(previousSections, value)
        : [value],
      survey
    );
    state.survey = survey;
  },
  [POP_PREVIOUS_SECTION]: state => {
    let survey = state.survey;
    let previousSections = get(survey, "sections.globals.previousSection");
    survey = setFP(
      "sections.globals.previousSection",
      previousSections !== null && Array.isArray(previousSections)
        ? dropRight(previousSections)
        : [],
      survey
    );
    state.survey = survey;
  },
  [SET_VALUE_DATE]: (state, { path, responsePath, value }) => {
    let survey = state.survey;
    const question = get(state.survey, path, {});
    let valueDate = moment(value,'DD/MM/YYYY').format('DD/MM/YYYY');
    survey = setFP(getPath(path, responsePath, "response"), valueDate, survey);
    survey = setFP(getPath(path, responsePath, "responseFull"), value, survey);
    survey = setFP(
      getPath(path, responsePath, "error"),
      getError(valueDate, question.rules),
      survey
    );
    state.survey = survey;
  },
  [RESET_VALUE]: (state, { path, value }) => {
    let survey = state.survey;
    survey = setFP(getPath(path), value, survey);
    state.survey = survey;
  },
  [SET_VALUE]: (state, { path, responsePath, value }) => {
    let survey = state.survey;
    const question = get(state.survey, path, {});
    survey = setFP(getPath(path, responsePath, "response"), value, survey);
    survey = setFP(getPath(path, responsePath, "error"), getError(value, question.rules), survey);
    const sectionPath = path.split(".");
    // on clear isBtNextClicked
    survey = setFP(
      getPath(`${sectionPath[0]}.${sectionPath[1]}`, "isBtNextClicked"),
      false,
      survey
    );
    state.survey = survey;
  },
  [SET_VALUE_GLOBAL]: (state, { path, responsePath, value }) => {
    let survey = state.survey;
    survey = setFP(getPath(path, responsePath), value, survey);
    state.survey = survey;
  },
  [TOGGLE_BT_NEXT]: (state, { sectionPath }) => {
    let survey = state.survey;
    survey = setFP(
      getPath(`sections.${sectionPath}`, "isBtNextClicked"),
      !get(state.survey, getPath(`sections.${sectionPath}`, "isBtNextClicked")),
      survey
    );
    state.survey = survey;
  },
  [SET_VALIDATE_SECTION]: (state, { path, responsePath, value }) => {
    let survey = state.survey;
    survey = set(survey, getPath(path, "isValidated"), value);
    state.survey = survey;
  },
  [RESET_QUESTION]: (state, { path, responsePath }) => {
    let survey = state.survey;
    const question = get(state.survey, path, {});
    execQuestionDeep(question, path, responsePath, (q, p, rp) => {
      survey = setFP(getPath(p, rp, "response"), null, survey);
      survey = setFP(getPath(p, rp, "error"), getError(null, q.rules), survey);
    });
    state.survey = survey;
  },
  [VALIDATE_SECTION]: (state, sectionName) => {
    let survey = state.survey;
    let sectionHasError = false;
    execSectionDeep(get(state, `survey.sections.${sectionName}`), sectionName, (q, p, rp) => {
      const isVisible = isRulesVisible(q.visibilityRules, state);
      if (isVisible) {
        const error = getError(getProp(q, rp, "response"), q.rules);
        sectionHasError = sectionHasError || error;
        survey = setFP(getPath(p, rp, "error"), error, survey);
        return true;
      }
      return false;
    });
    survey = setFP(`sections.${sectionName}.isValidated`, !sectionHasError, survey);
    state.survey = survey;
  },
  [CHECK_COMPLETED]: state => {
    // let allSectionsValidated = true;
    // let survey = state.survey;
    // forEach(survey.sections, (s, sn) => {
    //   if (sn !== "globals" && !s.isValidated) {
    //     allSectionsValidated = false;
    //   }
    // });
    // state.survey = survey;
    // désactiver en dev pour éviter la redirection vers page dejarepondu
    // if (!__DEV__) {
    //   state.isSurveyCompleted = allSectionsValidated;
    // }
  },
  [FETCH_SURVEY]: (state, codeClient) => {
    state.surveyWithClientCode = true;
    axios.get(`${process.env.VUE_APP_API_URL}/FormReco/getClient?codeClient=${codeClient}`)
      .then(({ data }) => {
        let allSectionsValidated = true;
        let survey = state.survey;
        execSurveyDeep(survey, (s, sn) => {
          if (get(data, s.serverPathValidated) === true) {
            survey = setFP(`sections.${sn}.isValidated`, true, survey);
          } else {
            allSectionsValidated = false;
          }
        }, (q, p, rp) => {
          const serverPath = getProp(q, "serverPath", rp);
          if (serverPath) {
            survey = setFP(
              getPath(p, rp, "response"),
              getResponseFromServer[q.questionType](data, serverPath, q.resetServerPath),
              survey,
            );
          }
        });
        console.log("/....data.../", data);
        survey = setFP("sections.globals.questions.codeClient.response", data.CodeClient, survey);
        state.survey = survey;
        if (allSectionsValidated) {
          state.isSurveyCompleted = true;
        }
      })
      .catch(e => console.log(e));
  },
  [GET_POSTAL_CODE]: (state, codeClient) => {
    let survey = state.survey;
    state.surveyWithClientCode = true;
    // let currentSession = sessionStorage.getItem('enquete-client');
    // appler axios get, pour aller chercher les postal code
    axios
      .get(`${process.env.VUE_APP_API_URL}/api/codespostal/`)
      .then(({ data }) => {
        survey.sections.globals.cpVilles = data;
        state.survey = survey;
        // puis les stocker dans le state
      })
      .catch(e => console.log(e));
  },
  [SET_SURVEY]: (state, survey) => {
    state.survey = survey;
  },
  [SET_ID]: (state, data) => {
    state.survey = setFP("sections.globals.questions.codeClient.response", data.id, state.survey);
  },
  [SET_FILLEUL]: (state) => {
    let survey = state.survey;
    let nbFilleuls = survey.sections.globals.nombreFilleuls;
    if (nbFilleuls < 3) {
      let filleuluuid = uuid.v1();
      let filleul = {
        "label": "Votre Filleul",
        "questionClass": "col-12 form-filleul",
        "questions": {
          "nom": {
            "serverPath": "Filleuls." + filleuluuid + ".Nom",
            "questionType": "input",
            "labelInput": "Nom *",
            "questionClass": "col-12 col-md-4",
            "rules": {
              "required": "Merci de renseigner son nom.",
              "alphaName": "Merci de vérifier votre saisie."
            }
          },
          "prenom": {
            "serverPath": "Filleuls." + filleuluuid + ".Prenom",
            "labelInput": "Prénom *",
            "questionType": "input",
            "questionClass": "col-12 col-md-4",
            "rules": {
              "required": "Merci de renseigner son prénom.",
              "alphaName": "Merci de vérifier votre saisie."
            }
          },
          "ville": {
            "serverPath": "Filleuls." + filleuluuid + ".Ville",
            "labelInput": "CP Ville *",
            "optionsURL": "FormReco/getCpVille",
            "questionType": "select",
            "questionClass": "col-12 col-md-4",
            "rules": {
              "required": "Merci de renseigner son code postal-ville."
            }
          },
          "email": {
            "serverPath": "Filleuls." + filleuluuid + ".Email",
            "labelInput": "Email *",
            "questionType": "input",
            "questionClass": "col-12 col-md-4",
            "rules": {
              "required": "Merci de renseigner son adresse email.",
              "email": "Merci de vérifier votre saisie."
            }
          },
          "telephone": {
            "serverPath": "Filleuls." + filleuluuid + ".noTelephone",
            "labelInput": "Téléphone *",
            "questionClass": "col-12 col-md-4",
            "questionType": "cleave",
            "cleaveOptions": {
              "blocks": [
                2,
                2,
                2,
                2,
                2
              ]
            },
            "rules": {
              "required": "Merci de renseigner son numéro de téléphone.",
              "regexp": {
                "model": "^[0-9]{10}$",
                "message": "Merci de vérifier votre saisie."
              }
            }
          },
          "removeFilleul": {
            "id": filleuluuid,
            "questionType": "removeFilleul",
            "buttonLabel": "Annuler l'ajout",
            "visibilityRules": {
              "sections.globals.nombreFilleuls": {
                "greater": 1
              }
            }
          }
        }
      };

      survey = setFP(`sections.forms.questions.filleuls.questions.${filleuluuid}`, filleul, survey);
      survey = setFP("sections.globals.nombreFilleuls", nbFilleuls + 1, survey);
      if (nbFilleuls >= 1) {
        let i = 1;
        forEach(survey.sections.forms.questions.filleuls.questions, (question, key) => {
          survey = setFP(`sections.forms.questions.filleuls.questions.${key}.label`, "Votre filleul n° " + i, survey);
          i++;
        });
      };
      state.survey = survey;
    }
  },
  [RESET_FILLEULS]: (state) => {
    let survey = state.survey;
    survey = setFP("sections.forms.questions.filleuls.questions", {}, survey);
    survey = setFP("sections.globals.nombreFilleuls", 0, survey);
    survey = setFP("sections.forms.isValidated", false, survey);
    state.survey = survey;
  },
  [REMOVE_FILLEUL]: (state, data) => {
    let survey = state.survey;
    let s = omitBy(survey.sections.forms.questions.filleuls.questions, (o, key) => key === data.id);
    survey = setFP("sections.forms.questions.filleuls.questions", s, survey);
    survey = setFP("sections.globals.nombreFilleuls", survey.sections.globals.nombreFilleuls - 1, survey);
    let i = 1;
    let nbFilleuls = survey.sections.globals.nombreFilleuls;
    console.log("/...nfilleuls.../", nbFilleuls);
    forEach(survey.sections.forms.questions.filleuls.questions, (question, key) => {
      if (nbFilleuls > 1) {
        survey = setFP(`sections.forms.questions.filleuls.questions.${key}.label`, "Votre filleul n° " + i, survey);
      } else {
        survey = setFP(`sections.forms.questions.filleuls.questions.${key}.label`, "Votre filleul", survey);
      }
      i++;
    });
    state.survey = survey;
  }
};

export default mutations;
