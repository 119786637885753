<template>
  <header v-if="section" class="app-header" :class="sectionName">
    <div class="container">
      <div class="header-title">
        <i v-if="section.icon" :class="section.icon" />
        <div v-if="section.title">{{ section.title }}</div>
      </div>
      <button @click="toggleNavBar" class="btn-menu">
        <i class="far fa-bars" />
      </button>
    </div>
  </header>
  <header v-else-if="routeName === 'Mentions légales'" class="app-header mentions_legales">
    <div class="header-title">
      <i class="glbicon-groupama" />
      <div>Mentions légales</div>
    </div>
    <button @click="toggleNavBar" class="btn-menu">
      <i class="far fa-bars" />
    </button>
  </header>
</template>
<script>
import { mapState, mapActions } from "vuex";

export default {
  computed: mapState({
    section(state) {
      return state.survey.sections[this.$route.params.section];
    },
    sectionName() {
      return this.$route.params.section;
    },
    routeName() {
      return this.$route.name;
    },
  }),
  methods: {
    ...mapActions(["toggleNavBar"]),
  },
};
</script>
<style lang="scss" scoped></style>
