 <template>
  <div class="container text-center">
    <div class="confirmation-block">
      <i class="icon-confirmation check" />
      <div class="confirm-message">
        <h1>Confirmation</h1>
        <div>Votre demande est bien prise en compte.</div>
        <div>
          Merci d’avoir parrainé vos proches, nous les contacterons dans les prochains jours pour
          leur faire découvrir les offres et les avantages Groupama.
        </div>
        <div>
          Dès que votre ou vos filleuls deviendront sociétaires* Groupama, nous vous adresserons en
          remerciement, des codes cadeaux à utiliser sur notre site Groupama parrainage.
        </div>
      </div>
    </div>
    <footer class="confirmation-footer">
      <div class="container">
        <div class="flexbox justifyCenter">
          <button @click="ResetFilleuls" class="btn btn-primary" > Parrainer d'autres filleuls </button>
        </div>
        <div class="mentions-legales">
          * Le programme de parrainage est soumis à conditions, il est valable dans les agences Groupama des départements 22, 29, 35, 44, 49, 56. Voir conditions détaillées en agence.
        </div>
      </div>
    </footer>
  </div>
</template>
<script>
import { mapMutations } from "vuex";

import router from "../router";

export default {
  methods: {
    ...mapMutations(["SET_FILLEUL", "RESET_FILLEULS"]),
    ResetFilleuls() {
      console.log(
        "/...Confirmation..../",
        this.$store.state.survey.sections.globals.nombreFilleuls
      );
      this.RESET_FILLEULS();
      this.SET_FILLEUL();
      router.push({
        name: "Questionnaire",
        params: {
          section: "forms",
        },
        query: this.$route.query,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
</style>
