<!-- Gestion d'une page du formulaire + actions avant changement de page -->
<template>
  <main class="container">
    <div v-if="section">
      <div v-if="section.label">{{ section.label }}</div>
      <question-layout
        v-for="(question, questionKey) in section.questions"
        :key="
          questionKey.indexOf('questions.') == -1 ? questionKey : questionKey.split('questions.')[1]
        "
        :questionName="
          questionKey.indexOf('questions.') == -1 ? questionKey : questionKey.split('questions.')[1]
        "
        :question="
          questionKey.indexOf('questions.') == -1
            ? question
            : Object.assign({}, questions[`${questionKey.split('questions.')[1]}`], question)
        "
        :path="
          questionKey.indexOf('questions.') == -1
            ? `sections.${$route.params.section}.questions.${questionKey}`
            : `sections.${$route.params.section}.questions.${questionKey.split('questions.')[1]}`
        "
        :responsePath="question.responsePath"
        :resetOtherQuestion="question.resetOtherQuestion"
      />
      <div class="required-field">* Champs obligatoires <br/> Nous vous informons que vous pouvez vous opposer au démarchage téléphonique en vous inscrivant gratuitement sur le site BLOCTEL (liste d’opposition au démarchage téléphonique) : <a href="https://www.bloctel.gouv.fr/">www.bloctel.gouv.fr</a></div>
    </div>
    <footer>
      <div class="row d-flex align-items-center">
        <div class="col-0" v-if="affichePrecedent">
          <router-link
            v-if="previousSection"
            :to="{
              name: 'Questionnaire',
              params: {
                section: previousSection,
                declarationId: declarationId,
              },
              query: {...arguments.$route.query, button: 'previous'}
            }"
            custom v-slot="{ navigate }"
          >
            <button @click="navigate" @keypress.enter="navigate" class="btn btn-primary">
              <span data-qa="precedent">Précédent</span>
            </button>
          </router-link>
        </div>

        <div
          class="col flexbox justifyEnd"
          v-if="nextSection && (haveDeclarations || $route.params.section !== 'main')"
        >
          <router-link
            :to="{
              name: 'Questionnaire',
              params: {
                section: nextSection,
                declarationId: declarationId,
              },
              query: {...$route.query, button: 'next'},
            }"
            custom v-slot="{ navigate }"
          >
            <button @click="navigate" @keypress.enter="navigate" :class="{ opacity: !isValide }" class="btn btn-primary" :disabled="!isValide">
              <span data-qa="suivant">
                {{ section && section.nextButton ? section.nextButton : "Suivant" }}
              </span>
            </button>
          </router-link>
        </div>

        <div class="col-12 col-sm col-big-btn-center" v-if="!nextSection">
          <router-link
            :to="{ name: 'Complet', query: $route.query }"
            custom v-slot="{ navigate }"
          >
            <button @click="navigate" :class="{ opacity: !isValide }" class="btn btn-primary btn-lg">
              <span data-qa="suivant">{{ confirmButton }}</span>
            </button>
          </router-link>
        </div>
      </div>
    </footer>
  </main>
</template>
<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { keys, find, get, map, filter, forEach, has } from "lodash";
import { isRulesVisible, getProp, execQuestionDeepError } from "../utils/SurveyUtils";
import store from "../store";
import { getError } from "../utils/validate";

export default {
  created() {
    if (this.$store.state.survey.sections.globals.nombreFilleuls > 0) {
      console.log("/....reset..../");
      this.RESET_FILLEULS();
      this.SET_FILLEUL();
    }
  },
  mounted() {
    if (this.$store.state.survey.sections.globals.nombreFilleuls === 0) {
      this.SET_FILLEUL();
    }
    // active popState si on utilise le bouton précédent du navigateur pour pas être perdu
    // console.log("---------");
    window.addEventListener("popstate", () => {
      while (
        this.$store.state.survey.sections.globals.previousSection &&
        this.$store.state.survey.sections.globals.previousSection.length !== 0 &&
        this.$store.state.survey.sections.globals.previousSection[
          this.$store.state.survey.sections.globals.previousSection.length - 1
        ] !== this.$route.params.section
      ) {
        this.popPreviousSection();
      }
      this.popPreviousSection();
    });
  },
  computed: mapState({
    confirmButton(state) {
      return state.survey.sections.globals.confirmButton;
    },
    sectionList(state) {
      return state.survey.sections;
    },
    declarationId(state) {
      return this.$route.params.declarationId;
    },
    sectionNameList(state) {
      return filter(keys(state.survey.sections), name => name !== "globals");
    },
    section(state) {
      return state.survey.sections[this.$route.params.section];
    },
    previousSection(state) {
      // récupère la section précédente
      let previousSections = get(this.$store.state.survey, "sections.globals.previousSection");

      if (previousSections === null || previousSections === undefined) {
        return null;
      }

      let i = 1;
      while (i <= previousSections.length) {
        // si la section précédente est égal à la section courante on regarde celle d'avant
        if (previousSections[previousSections.length - i] !== this.$route.params.section) {
          return previousSections[previousSections.length - i];
        }
        i++;
      }
      return null;
    },
    visibilityRulesBtNext(state) {
      return state.survey.sections[this.$route.params.section].visibilityRulesBtNext;
    },
    nextSection(state) {
      // const sections = keys(state.survey.sections);
      // si les visibility rules de la section ne sont pas respectées, on regarde la section d'après ...
      let section = null;
      if (this.section) {
        forEach(this.section.next, function (nextSection, name) {
          if (isRulesVisible(nextSection.visibilityRules, state)) {
            section = name;
          }
        });
      }
      return section;
    },
    questions(state) {
      return state.survey.questions;
    },
    affichePrecedent(state) {
      return true;
    },
    isValide(state) {
      const sectionName = this.$route.params.section;
      // callback -> return true if we need to search error in survey or false if we have get error
      let callback = (q, p, rp) => {
        const isVisible = isRulesVisible(q.visibilityRules, state);
        if (isVisible) {
          let error;
          // maxAnswers need to access to global state
          if (has(q.rules, "maxAnswers")) {
            error = getError(getProp(state.survey, q.rules.maxAnswers.path, ""), q.rules);
            if (error) {
              // dispatch error
              this.checkError({
                path: p,
                responsePath: rp,
                value: getProp(state.survey, q.rules.maxAnswers.path, ""),
              });
            } else {
              this.checkError({
                path: p,
                responsePath: rp,
                value: null,
              });
            }
          } else {
            error = getError(getProp(q, rp, "response"), q.rules);
          }
          return !error;
        }
        return true;
      };
      let section = get(state, `survey.sections.${sectionName}`);
      // regarder les erreurs sur les questions de la section
      const arrayError = section
        ? map(section.questions, (question, questionName) => {
            // console.log("DEBUG question, questionName", question, questionName);
            return !execQuestionDeepError(
              question,
              `sections.${sectionName}.questions.${questionName}`,
              question.responsePath,
              callback
            );
          })
        : [];
      // console.log("DEBUG arrayError", arrayError);
      return find(arrayError, bool => bool === false) === undefined;
    },
    haveDeclarations(state) {
      let declarations = getProp(this.$store.state.survey, "sections.globals.declarations");
      return (
        find(
          declarations,
          element =>
            element !== undefined &&
            element !== null &&
            typeof element === "object" &&
            Object.keys(element).length !== 0
        ) !== undefined
      );
    },
  }),
  methods: {
    ...mapMutations(["SET_FILLEUL", "RESET_FILLEULS"]),
    ...mapActions([
      "validateSection",
      "sendSurvey",
      "sendBuilding",
      "resetValue",
      "setValidateSection",
      "toggleBtNext",
      "addPreviousSection",
      "popPreviousSection",
      "setValueGlobal",
      "setValue",
      "checkError",
      "checkCompleted",
    ]),
    changeValueValidateSection(path, value) {
      this.setValidateSection({ path, value });
    },
    checkSubmit() {
      console.log("checkSubmit");
      if (this.errorAdresse || this.errorTelephone || this.errorCpVille) {
        this.errorsSubmit = true;
      }
    },
    onRouteChange(to, from, next) {
      // si déja répondu, on ne renvoi pas le formulaire
      if (to.path.toLowerCase() !== "/dejarepondu") {
        // validation de la section et envoi formulaire à l'API
        this.validateSection(from.params.section).then(props => {
          if (this.section.isValidated === true) {
            console.log("-----query-----");
            store.dispatch("sendSurvey", {
              query: from.query,
              survey: this.$store.state.survey,
              completed: !this.nextSection,
            });
            window.scroll(0, 0);
          }
          next(this.section.isValidated === true);
          window.scroll(0, 0);
        });
      } else {
        next();
        window.scroll(0, 0);
      }
    },
    calculateAge(birthday) {
      if (birthday) {
        let ageDifMs =
          Date.now() -
          new Date(birthday.split("/")[2], birthday.split("/")[1], birthday.split("/")[0]);
        let ageDate = new Date(ageDifMs);
        return Math.abs(ageDate.getUTCFullYear() - 1970);
      } else {
        return null;
      }
    },
  },
  beforeRouteEnter(to, from, next) {
    let codeClient =
      store.state.survey.sections.globals.questions.codeClient &&
      store.state.survey.sections.globals.questions.codeClient.response;

    // si la page déjà répondu est activé, on check si le client a déjà rempli le formulaire
    if (store.state.dejaReponduPage && (!codeClient || to.query.codeClient === codeClient)) {
      store.dispatch("checkCompleted");
      if (store.state.isSurveyCompleted) {
        return next("/DejaRepondu");
      }
    }

    // si la page noClient est activé, on affiche une page d'erreur si le client est renseigné
    if (store.state.noClientPage && !to.query.codeClient) {
      return next("/noClient");
    }
    return next();
  },
  // Toutes les actions effectués avant de changer de page sont ici
  beforeRouteUpdate(to, from, next) {
    const button = to.query.button;

    // gestion tracker
    // let tracker;
    // if (this.$store.state.survey.sections[to.params.section].tracking) {
    //   tracker = this.$store.state.survey.sections[to.params.section].tracking;
    //   console.log("track onRouteChange");
    //   console.log(">>>>>>>>>>>>>>>>>", tracker.track);
    //   tracking(tracker.track);
    // }

    if (button === "previous") {
      // si on appui sur précédent on reset le formulaire incomplet
      // this.resetValue({ path: `sections.${this.$route.params.section}`, value: survey.sections[this.$route.params.section] });
      // et dépile le dernière element de l'historique des précédentes versions
      this.popPreviousSection();

      next();
    } else if (button === "linkField") {
      // si on arrive sur le formulaire de déclaration via un "LinkField"
      // on ajoute la section courante à l'historique des sections
      if (from.params.section !== to.params.section) {
        this.addPreviousSection(from.params.section);
      }
      next();
    } else if (button === "header") {
      // si on clique sur l'header
      // on vérifie si on peut y aller
      let headerList = this.$store.state.survey.sections.globals.header;

      // on créer une liste nomRoute => index pour plus facilement comparer leurs positions
      let reverseHeaderList = [];
      for (let index = 0; index < headerList.length; index++) {
        reverseHeaderList[headerList[index].route] = index;
      }

      // si on clique sur une section précédente
      if (
        from.params.section !== undefined &&
        reverseHeaderList[to.params.section] < reverseHeaderList[from.params.section]
      ) {
        // on dépile les elements de l'historique des précédentes versions jusque la où on va
        while (
          this.$store.state.survey.sections.globals.previousSection &&
          this.$store.state.survey.sections.globals.previousSection.length !== 0 &&
          this.$store.state.survey.sections.globals.previousSection[
            this.$store.state.survey.sections.globals.previousSection.length - 1
          ] !== to.params.section
        ) {
          this.popPreviousSection();
        }
        this.popPreviousSection();
        next();
      } else if (
        from.params.section !== undefined &&
        reverseHeaderList[to.params.section] > reverseHeaderList[from.params.section] &&
        this.$store.state.survey.sections[to.params.section].isValidated
      ) {
        // sinon si on clique sur une section suivante
        let i = 1;
        while (
          headerList[reverseHeaderList[from.params.section] + i] !== undefined &&
          headerList[reverseHeaderList[from.params.section] + i].route !== to.params.section
        ) {
          this.addPreviousSection(headerList[reverseHeaderList[from.params.section] + i - 1].route);
          i++;
        }
        this.addPreviousSection(headerList[reverseHeaderList[from.params.section] + i - 1].route);

        next();
      }
    } else {
      // sinon on regarde si le formulaire est valide
      // on ajoute la section courante à l'historique des sections
      if (from.params.section !== to.params.section) {
        this.addPreviousSection(from.params.section);
      }
      this.onRouteChange(to, from, next);
    }
  },
  beforeRouteLeave(to, from, next) {
    if (to.path === "/completer") {
      this.checkSubmit();
    }

    if (!this.errorsSubmit) {
      this.onRouteChange(to, from, next);
    }
  },
};
</script>
<style lang="scss" scoped></style>
