<template>

</template>
<script>
import { mapState, mapActions } from "vuex";
import { getProp } from "../../utils/SurveyUtils";

export default {
  computed: mapState({
    title: state => state.survey.title,
    toogleNavbar: state => state.toogleNavbar,
    icon: state => state.survey.icon,
    isSurveyCompleted: state => state.isSurveyCompleted,
    header: state => state.survey.sections.globals.header,
  }),
  methods: {
    ...mapActions(["toggleNavBar"]),
    sections(section) {
      return getProp(this.$store.state.survey.sections, section, "isValidated");
    },
  },
};
</script>
<style lang="scss" scoped></style>
