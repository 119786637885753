import { get, map } from "lodash";

// Défini à partir du type d'input comment formater la donnée avant de l'envoyer à l'api
export const getResponseFromServer = {
  input: (serverData, serverPath, resetServerPath) => {
    if (get(serverData, resetServerPath) === true) {
      return null;
    }
    return get(serverData, serverPath);
  },
  cleave: (serverData, serverPath) => get(serverData, serverPath),
  select: (serverData, serverPath) => get(serverData, serverPath),
  radio: (serverData, serverPath) => {
    const serverResponse = get(serverData, serverPath);
    if (serverResponse === true) {
      return "oui";
    } else if (serverResponse === false) {
      return "non";
    } else {
      return serverResponse;
    }
  },
  checkbox: (serverData, serverPaths, resetServerPath) => {
    if (get(serverData, resetServerPath) === true) {
      return null;
    }
    const response = [];
    map(serverPaths, (serverPath, optionKey) => {
      if (get(serverData, serverPath) === true) {
        response.push(optionKey);
      }
    });

    return response;
  },
  "checkbox-classic": (serverData, serverPath) => {
    const serverResponse = get(serverData, serverPath);
    if (serverResponse === true) {
      return "oui";
    } else if (serverResponse === false) {
      return "non";
    } else {
      return serverResponse;
    }
  },
  "date-month-year": (serverData, serverPaths) => ({
    year: get(serverData, serverPaths.year),
    month: get(serverData, serverPaths.month)
  }),
  calendar: (serverData, serverPath) => get(serverData, serverPath),
  download: (serverData, serverPath) => get(serverData, serverPath),
  text: (serverData, serverPath) => get(serverData, serverPath),
  link: (serverData, serverPath) => get(serverData, serverPath),
  textarea: (serverData, serverPath) => get(serverData, serverPath),
  modal: (serverData, serverPath) => get(serverData, serverPath),
  pricingTable: (serverData, serverPath) => get(serverData, serverPath),
  membersTable: (serverData, serverPath) => {
    return get(serverData, serverPath);
  },
  calcul: (serverData, serverPath) => {
    return {
      calculYear: get(serverData, serverPath.calcul_year),
      calculMonth: get(serverData, serverPath.calcul_month)
    };
  }
};

export const getResponseFromSurvey = {
  input: (survey, rp, serverPath, resetpath, index, path) => {
    const surveyResponse = get(survey, rp);
    const values = {
      [resetpath]: surveyResponse === null ? true : null
    };
    values[serverPath] = surveyResponse;
    return values;
  },
  cleave: (survey, path) => get(survey, path),
  select: (survey, path) => get(survey, path),
  radio: (survey, path) => {
    const surveyResponse = get(survey, path);
    if (surveyResponse === "oui") {
      return true;
    } else if (surveyResponse === "non") {
      return false;
    }
    return get(survey, path);
  },
  checkbox: (survey, path, serverPaths, resetpath) => {
    const surveyResponse = get(survey, path);
    const values = {
      [resetpath]: surveyResponse === null ? true : null
    };
    map(serverPaths, (serverPath, value) => {
      values[serverPath] = surveyResponse && surveyResponse.includes(value);
    });
    return values;
  },
  "checkbox-classic": (survey, path) => {
    const surveyResponse = get(survey, path);
    if (surveyResponse === "oui") {
      return true;
    } else if (surveyResponse === "non") {
      return false;
    }
    return get(survey, path);
  },
  "date-month-year": (survey, path, serverPaths) => {
    return {
      [serverPaths.year]: get(survey, `${path}.year`),
      [serverPaths.month]: get(survey, `${path}.month`)
    };
  },
  calendar: (survey, path) => get(survey, path),
  download: (survey, path) => get(survey, path),
  text: (survey, path) => get(survey, path),
  link: (survey, path) => get(survey, path),
  textarea: (survey, path) => get(survey, path),
  modal: (survey, path) => get(survey, path),
  pricingTable: (survey, path) => get(survey, path),
  membersTable: (survey, path, serverPath, resetServerPath, index) => {
    return get(survey, "sections.globals.questions.members[" + index + "].response");
  },
  calcul: (survey, path, serverPaths) => {
    return {
      [serverPaths.response.year]: get(survey, `${path}.calcul_year`),
      [serverPaths.response.month]: get(survey, `${path}.calcul_month`)
    };
  }
};
