<template>
  <input
    :class="[question.fieldClass]"
    :checked="response"
    @click="onClick()"
    type="checkbox"
    id="checkbox"
  />
  <label for="checkbox"></label>
</template>
<script>
import { getOptions, getProp, getPath } from "../../utils/SurveyUtils";

export default {
  props: ["question", "path", "responsePath"],
  computed: {
    response() {
      return getProp(this.question, this.responsePath, "response");
    }
  },
  methods: {
    getOptions,
    getPath,

    onClick() {
      return this.$store.dispatch("setValue", {
        path: this.path,
        responsePath: this.responsePath,
        value: !this.response || false
      });
    }
  }
};
</script>
