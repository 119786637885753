<template>
  <div :class="`${question.answerColClass || 'col'} field`">
    <div class="dropzone">
      <input
        type="file"
        ref="inputFile"
        id="inputFile"
        name="inputFile"
        @change="submitFiles"
        :accept="question.accept"
        style="display: none;"
      />
      <form ref="fileform">
        <div
          class="btn"
          v-if="response === null || response === undefined"
          @drop="submitFiles"
          @click="$refs.inputFile.click()"
        >
          <i class="fas fa-download"></i>
          <span>{{ question.uploadBtnLabel }}</span>
        </div>
      </form>
      <div class="files-list" v-if="response !== null && response !== undefined">
        <div class="btn">
          <span>{{ fileName }}</span>
          <a class="remove" @click="removeFile">
            <i class="far fa-times-circle"></i>
          </a>
        </div>
      </div>
    </div>
    <label v-if="question.labelInput">{{ question.labelInput }}</label>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { split, replace, get } from "lodash";
import { getProp } from "../../utils/SurveyUtils";
const axios = require("axios");

export default {
  props: ["question", "path", "responsePath"],
  data: function () {
    return {
      dragAndDropCapable: true,
      fileName: "",
    };
  },
  computed: {
    response() {
      return getProp(this.question, this.responsePath, "response");
    },
  },
  methods: {
    ...mapActions(["setValue", "checkErrorFP"]),
    /* Removes a select file the user has uploaded */
    removeFile() {
      let responses = {};
      responses.id = this.$store.state.survey.sections.identification.questions.exportId.response;
      let responsePath = this.responsePath;
      let path = this.path;
      this.setValue({ path, responsePath, value: null });
      // we delete all files with the id in the db
      axios.delete(`${process.env.VUE_APP_API_URL}/api/uploads/`, responses);
    },
    /* Submits the files to the server */
    submitFiles(e) {
      let file = e.dataTransfer
        ? e.dataTransfer.files[0]
        : e.srcElement
        ? e.srcElement.files[0]
        : null;
      let extensions = split(replace(replace(this.question.accept, / /g, ""), /\./g, ""), ",");
      this.checkErrorFP({
        path: this.path,
        response: this.responsePath,
        value: { file: file, extensions: extensions },
      }).then(res => {
        if (get(this.$store.state.survey, this.path).error) {
          return;
        }
        this.setValue({
          path: this.path,
          response: this.responsePath,
          value: { file: file, extensions: extensions },
        });
        let formData = new FormData();
        formData.append("file", file);
        formData.append(
          "id",
          this.$store.state.survey.sections.identification.questions.exportId.response
        );
        axios
          .post(`${process.env.VUE_APP_API_URL}/api/uploads`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(() => {
            this.fileName = file.name;
          });
      });
    },
  },
  mounted() {
    if (this.dragAndDropCapable) {
      /* Listen to all of the drag events and bind an event listener to each for the fileform. */
      ["drag", "dragstart", "dragend", "dragover", "dragenter", "dragleave", "drop"].forEach(
        function (evt) {
          this.$refs.fileform.addEventListener(
            evt,
            function (e) {
              e.preventDefault();
              e.stopPropagation();
            },
            false
          );
        }.bind(this)
      );
    }
  },
};
</script>
