<template>
  <a class="btn" @click="removeFilleul({ id: question.id })" target="_blank">{{
    question.buttonLabel
  }}</a>
</template>
<script>
import { mapActions, mapState } from "vuex";

export default {
  props: ["question", "path", "responsePath"],
  computed: {
    ...mapState({
      nbFilleuls: state => {
        return state.sections.globals.nombreFilleuls;
      },
    }),
  },
  methods: {
    ...mapActions({
      removeFilleul: "removeFilleulState",
    }),
  },
};
</script>
