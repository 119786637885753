import { createStore } from "vuex";
import VuexPersistence from "vuex-persist";
import mutations from "./mutations";
import actions from "./actions";
import state from "./state";
import getters from "./getters";

// add local session storage
const vuexPersistState = new VuexPersistence({
  key: "vuex-survey-starter",
  storage: sessionStorage,
  reducer: state => ({
    devisCaisse: state.devisCaisse,
    survey: state.survey,
    surveyWithClientCode: state.surveyWithClientCode,
    isSurveyCompleted: state.isSurveyCompleted,
  }),
});

const store = createStore({
  plugins: [vuexPersistState.plugin],
  state,
  mutations,
  actions,
  getters,
  modules: {},
});

export default store;
