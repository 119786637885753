import { createRouter, createWebHistory } from "vue-router";

import SurveyForm from "./SurveyForm";
import NotFoundComponent from "./NotFoundComponent";
import SurveyCompleted from "./SurveyCompleted";
import DejaRepondu from "./DejaRepondu";
import LegalNotice from "./LegalNotice";
import survey from "../../data/survey.json";

const routes = [
  {
    name: "notFound",
    path: "/:pathMatch(.*)*",
    component: NotFoundComponent,
    meta: {
      title: "404 Not Found"
    }
  },
  {
    name: "Accueil",
    path: "/",
    redirect: {
      name: "Questionnaire",
      params: { section: Object.keys(survey.sections)[1] }
    }
  },
  {
    name: "Complet",
    path: "/completer/:param?",
    component: SurveyCompleted,
    meta: {
      title: "Complet",
      keepAlive: true
    }
  },
  {
    name: "DejaRepondu",
    path: "/dejarepondu/:param?",
    component: DejaRepondu,
    meta: {
      title: "Deja Repondu",
      keepAlive: true
    }
  },
  {
    name: "Mentions légales",
    path: "/mentions_legales/:param?",
    component: LegalNotice,
    meta: {
      title: "Mentions légales",
      keepAlive: true
    }
  },
  {
    name: "Questionnaire",
    props: route => ({ query: route.query.q }),
    path: "/:section/:param?",
    component: SurveyForm,
    meta: {
      title: "Questionnaire"
    }
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  }
});

router.afterEach(to => {
  if (to.meta.title !== undefined) {
    document.title = "Formulaire Parrainage";
  }
});

export default router;
