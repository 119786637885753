<template>
  <div class="col grid">
    <div class="col col-month">
      <v-select
        placeholder="Mois"
        :options="monthOptions"
        :class="question.fieldClass"
        v-model="month"
        @input="onChange"
        selectOnTab
      />
    </div>
    <div class="col col-year">
      <v-select
        placeholder="Année"
        :options="yearOptions"
        :class="question.fieldClass"
        v-model="year"
        @input="onChange"
        selectOnTab
      />
    </div>
    <div class="col" v-if="month && !year">
      <div class="error error-date">Merci de sélectionner une année</div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import VSelect from "vue-select";
import { range } from "lodash";
import moment from "moment";

import { getProp } from "../../utils/SurveyUtils";

export default {
  props: ["question", "path", "responsePath"],
  data() {
    const response = getProp(this.question, this.responsePath, "response");
    return {
      month: response && response.month,
      year: response && response.year,
      monthOptions: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"],
      yearOptions: range(moment().year(), moment().year() + 15),
    };
  },
  methods: {
    ...mapActions(["setValue"]),
    onChange() {
      if (this.year || !this.month) {
        this.setValue({
          path: this.path,
          responsePath: this.responsePath,
          value: {
            month: this.month,
            year: this.year,
          },
        });
      }
    },
  },
  components: {
    VSelect,
  },
};
</script>
