<template>
  <div class="tooltip-container">
    <a @click="infoBulle" class="btn-tooltip"><i class="fas fa-info" /></a>
    <div class="tooltip-block" id="tooltip-info">
      <img
        v-if="question.tooltipImage"
        :src="require(`@/assets/images/${question.tooltipImage}`)"
      />
      <div v-if="question.tooltipText" class="tooltip-text">{{ question.tooltipText }}</div>
      <a @click="fermerInfoBulle" class="btn-close"><i class="far fa-times" /></a>
    </div>
  </div>
</template>
<script>
export default {
  props: ["question"],
  methods: {
    infoBulle() {
      let info = document.getElementById("tooltip-info");
      console.log(info);
      info.classList.add("active");
    },
    fermerInfoBulle() {
      let info = document.getElementById("tooltip-info");
      info.classList.remove("active");
    },
  },
};
</script>
