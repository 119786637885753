<template>
  <div :class="`${question.answerColClass || 'col'} field`">
    <v-select
      :name="question.labelInput"
      :taggable="question.taggable"
      :options="options"
      :disabled="question.disabled && isDisabled"
      :placeholder="question.placeholder"
      :class="question.fieldClass"
      :modelValue="response"
      @option:selected="value => setValue({ path, responsePath, value })"
      @search="onSearch"
      selectOnTab
      :searchable="question.searchable"
      push-tags
    >
      <template #no-options>Pas de résultats</template>
    </v-select>
    <label v-if="question.labelInput">{{ question.labelInput }}</label>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import axios from "axios";
import { map } from "lodash";
import VSelect from "vue-select";

import { getOptions, getProp } from "../../utils/SurveyUtils";

export default {
  components: {
    VSelect,
  },
  props: ["question", "path", "responsePath"],
  data() {
    return {
      options: map(getOptions(this.question.options), label => {
        if (label.codePostal) {
          console.log("label : ", label);
          return label.codePostal + " " + label.ville;
        } else {
          return label;
        }
      }),
    };
  },
  computed: {
    ...mapGetters(["isDisabled"]),
    response() {
      return getProp(this.question, this.responsePath, "response");
    },
    stockedResponse() {
      return getProp(this.question, "stockedResponse");
    },
  },
  methods: {
    ...mapActions(["setValue"]),
    optionsState() {
      // il faut aller chercher dans la state l'endroit ou sont stocker les options
      // ensuite il faut faire un map pour tous les parcours

      // this.question.optionsState (value du chemin)
      // toute les cp viles = get du state survey, avec value du chemin
      const cpVilles = getProp(this.store.state, this.question.optionsState);
      return map(cpVilles, o => o.codePostal + o.ville);
    },
    onInput(value) {
      this.$store.dispatch("setValue", {
        path: this.path,
        responsePath: "stockedResponse",
        value: null,
      });
      this.$store.dispatch("setValue", {
        path: this.path,
        responsePath: this.responsePath,
        value: value,
      });
    },
    onSearch(search, loading, spinner) {
      // empty previous value while searching
      if (search && !(this.stockedResponse && this.stockedResponse.response)) {
        this.$store.dispatch("setValue", {
          path: this.path,
          responsePath: "stockedResponse",
          value: this.response,
        });
        this.$store.dispatch("setValue", {
          path: this.path,
          responsePath: this.responsePath,
          value: null,
        });
      } else if (!search && this.stockedResponse && this.stockedResponse.response) {
        this.$store.dispatch("setValue", {
          path: this.path,
          responsePath: this.responsePath,
          value: this.stockedResponse.response,
        });
        this.$store.dispatch("setValue", {
          path: this.path,
          responsePath: "stockedResponse",
          value: null,
        });
      }
      // si optionsURL, on envoi une requete
      if (this.question.optionsURL && search.length > 1) {
        loading(true);
        axios
          .get(`${process.env.VUE_APP_API_URL}/${this.question.optionsURL}/${search}`)
          .then(res => {
            this.options = map(res.data, d => `${d.ville} (${d.codePostal})`);
            loading(false);
          });
      }
    },
  },
};
</script>
